import { useState, useEffect } from 'react'
import { Modal, TextInput, Textarea, Button, Group, Select } from '@mantine/core'
import { useForm } from '@mantine/form'

const ErrorReportForm = () => {
  const [opened, setOpened] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('')
  const [countryFromUrl, setCountryFromUrl] = useState('')

  const form = useForm({
    initialValues: {
      name: '',
      title: '',
      description: '',
      errorType: ''
    },
    validate: {
      name: (value) => (value.length > 2 ? null : 'Name too short'),
      title: (value) => (value.length > 2 ? null : 'Title too short'),
      description: (value) => (value.length > 4 ? null : 'Short description'),
      errorType: (value) => (value ? null : 'Select an error')
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const fullUrl = window.location.origin + window.location.pathname
      setCurrentUrl(fullUrl)

      const pathSegments = window.location.pathname.split('/')
      const localeSegment = pathSegments[1] // formato "en-AR"
      const country = localeSegment?.split('-')[1] || '' // extraemos "AR" de "en-AR"
      setCountryFromUrl(country)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'e') {
        event.preventDefault()
        setOpened(true)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  const sendErrorToApi = async (values) => {
    try {
      const response = await fetch('/api/report-error', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      })

      if (!response.ok) {
        throw new Error('Error al enviar el reporte')
      }

      console.log('Enviado a n8n:', values)
    } catch (error) {
      console.error('Error al enviar el reporte:', error)
    }
  }

  const handleSubmit = (values) => {
    sendErrorToApi({
      ...values,
      url: currentUrl,
      country: countryFromUrl
    })
    setOpened(false)
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Report an Error"
      >
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput
            label="Name"
            placeholder="Your name"
            {...form.getInputProps('name')}
            required
          />
          <TextInput
            label="Title"
            placeholder="Error title"
            {...form.getInputProps('title')}
            required
            mt="md"
          />
          <Select
            label="Error type"
            placeholder="Select an error"
            dropdownPosition="bottom"
            data={[
              { value: 'Metadata', label: 'Metadata' },
              { value: 'UI/UX', label: 'UI/UX' },
              { value: 'Availability', label: 'Availability' },
              { value: 'Slow Performance', label: 'Slow Performance' },
              { value: 'Loading Error', label: 'Loading Error' },
              { value: 'Mapping', label: 'Mapping' },
              { value: 'Other', label: 'Other' }
            ]}
            {...form.getInputProps('errorType')}
            required
            mt="md"
          />
          <Textarea
            label="Error description"
            placeholder="Describe what happened"
            {...form.getInputProps('description')}
            required
            minRows={4}
            mt="md"
          />
          <Group position="right" mt="md">
            <Button sx={{ backgroundColor: '#7B39B6' }} type="submit">
              Send
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  )
}

export default ErrorReportForm
