import {
  Button,
  Divider,
  Drawer,
  Group,
  ScrollArea,
  Stack,
  Switch,
  Text
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import Cookies from 'js-cookie'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const CookieBanner = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const [functionalConsent, setFunctionalConsent] = useState(false)
  const [analysisConsent, setAnalysisConsent] = useState(false)
  const [personalisedConsent, setPersonalisedConsent] = useState(false)
  const { locale } = useRouter()

  const t = useTranslations('Cookies')

  const handleAcceptAll = () => {
    setFunctionalConsent(true)
    setAnalysisConsent(true)
    setPersonalisedConsent(true)
    saveConsent(true, true, true)
    close()
  }

  const saveConsent = (functional, analysis, personalised) => {
    const consentObj = {
      essential: true,
      functional: functional,
      analysis: analysis,
      personalised: personalised
    }

    Cookies.set('cookie_consent', JSON.stringify(consentObj), { expires: 365 })

    if (functional) {
      Cookies.set('NEXT_LOCALE', locale, { expires: 365 })
      close()
    }

    if (analysis) {
      initializeAnalytics()
    }
    if (personalised) {
      initializePersonalisedAds()
    }
  }

  const gtag = (...args) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(args)
    }
  }

  const initializeAnalytics = () => {
    if (typeof window !== 'undefined' && !window.gtmScript) {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-KZ8PNZVJ`
      script.async = true
      document.head.appendChild(script)

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime()
      })

      window.gtmScript = true
    }
    if (typeof window !== 'undefined' && !window.gtag) {
      window.dataLayer = window.dataLayer || []
      window.gtag = gtag
      gtag('js', new Date())
      gtag('config', 'G-8HJZCG3ZWC')
    }
  }

  const initializePersonalisedAds = () => {
    // Personalized ad initialization code (e.g., Facebook Pixel or Google Ads)
  }

  const handleSavePreferences = () => {
    saveConsent(functionalConsent, analysisConsent, personalisedConsent)
    close()
  }

  useEffect(() => {
    // Check if the cookie consent has already been set
    const consent = Cookies.get('cookie_consent')
    if (!consent) {
      open()
    }
  }, [open])

  return (
    opened && (
      <>
        <Drawer
          opened={opened}
          closeOnClickOutside={false}
          title="Privacy Information"
          closeOnEscape={false}
          position="bottom"
          size="auto"
          withCloseButton={false}
        >
          <Drawer.Body>
            <Stack justify="space-evenly">
              <Text fz="sm">{t('bannerText')}</Text>
              <ScrollArea h="25vh" my={12}>
                <Switch
                  checked={true}
                  color="teal"
                  disabled
                  label={t('essentialLabel')}
                />
                <Text fz="xs">{t('essentialDescription')}</Text>
                <Divider my="sm" />
                <Switch
                  checked={functionalConsent}
                  color="teal"
                  onChange={(e) => setFunctionalConsent(e.target.checked)}
                  label={t('functionalLabel')}
                />
                <Text fz="xs">{t('functionalDescription')}</Text>
                <Divider my="sm" />
                <Switch
                  checked={analysisConsent}
                  color="teal"
                  onChange={(e) => setAnalysisConsent(e.target.checked)}
                  label={t('analysisLabel')}
                />
                <Text fz="xs">{t('analysisDescription')}</Text>
                <Divider my="sm" />
                <Switch
                  checked={personalisedConsent}
                  color="teal"
                  onChange={(e) => setPersonalisedConsent(e.target.checked)}
                  label={t('personalisedLabel')}
                />
                <Text fz="xs">{t('personalisedDescription')}</Text>
              </ScrollArea>
              <Group position="right">
                <Button onClick={handleSavePreferences}>
                  {t('saveSettingsButton')}
                </Button>
                <Button
                  sx={{ backgroundColor: '#7B39B6' }}
                  onClick={handleAcceptAll}
                >
                  {t('acceptAllButton')}
                </Button>
              </Group>
            </Stack>
          </Drawer.Body>
        </Drawer>
      </>
    )
  )
}

export default CookieBanner
